.App {
  text-align: center;
  
  height: 100vh;
  flex-direction: column;

}

body{
  margin: 0;
  padding: 0;
}
.inputs{
  height: 400px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: rgb(10, 128, 10);
  
}

.inputs input{
  width: 200px;
  height: 50px;
  font-size: 18px;
  margin: 10px;
  padding-left: 10px;
}

.inputs button{
  border: none;
  width: 150px;
  height: 50px;
  background-color: rgb(8, 123, 224);
  color: azure;
  border-radius: 10px;
  font-size: 15px;
}

button:hover{
  cursor: pointer;
}

.listOfMusic{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 20px;
}
.musicContainer{
display: flex;
flex-direction: row;
margin: 10px;
font-family: 'Courier New', Courier, monospace;
}

.music{
  width: 400px ;
  height: 60px;
  background-color:  rgb(27, 107, 3);
  color: white;
  font-family: 'Courier New', Courier, monospace;
  display: flex;
  margin: 10px;
}
.music h3{
  margin-left: 15px;

}
.musicContainer button{
  width: 60px;
  height: 60px;
  border: 2px solid black;
  background-color: rgb(27, 107,3);
  color: white;
}
#removeBtn{
  border-left: none;
}